import React, { useState, useRef, useEffect } from 'react'
import { render } from 'react-dom'
import images from './img/*.png'
import logo from './img/logo.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRandom } from '@fortawesome/free-solid-svg-icons/faRandom'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import loadImages from 'image-promise'

type BeastDescription = JSX.Element | string

type BeastSection = {
  image: string
  name: string
  description: BeastDescription
}

type Beast = {
  top: BeastSection
  middle: BeastSection
  bottom: BeastSection
}

function Carousel(props: {
  children: JSX.Element[]
  index: number
  onChange?: (i: number) => void
}) {
  const slider = useRef(null as Slider | null)
  const [index, setIndex] = useState(props.index)
  const interactive = !!props.onChange
  useEffect(() => {
    if (!slider.current) return
    if (index === mod(props.index + 1, props.children.length)) {
      slider.current.slickPrev()
    } else if (index === mod(props.index - 1, props.children.length)) {
      slider.current.slickNext()
    } else {
      slider.current.slickGoTo(props.index)
    }
    setIndex(props.index)
  }, [props.index])
  useEffect(() => props.onChange && props.onChange(index), [index])
  return (
    <Slider
      ref={slider}
      accessibility={false}
      arrows={false}
      afterChange={setIndex}
      initialSlide={props.index}
      swipe={interactive}
      draggable={interactive}
      touchMove={interactive}
      swipeToSlide={interactive}
    >
      {props.children.map((child, i) => (
        <div className="content-parent">{child}</div>
      ))}
    </Slider>
  )
}

function Section(props: {
  sections: BeastSection[]
  index: number
  onChange: (i: number) => void
}) {
  return (
    <div className="section">
      <button
        title="Mostrar la sección anterior"
        onClick={() => props.onChange(props.index + 1)}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <Carousel index={props.index} onChange={props.onChange}>
        {props.sections.map((section, i) => (
          <div className="content">
            <div className="name">{'\u00A0'}</div>
            <img key={i} src={section.image} />
            <div className="name">{section.name || '\u00A0'}</div>
          </div>
        ))}
      </Carousel>
      <button
        title="Mostrar la siguiente sección"
        onClick={() => props.onChange(props.index - 1)}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  )
}

function Description(props: {
  descriptions: BeastDescription[]
  index: number
}) {
  return (
    <Carousel index={props.index}>
      {props.descriptions.map((description, i) => (
        <span className="description" data-i={i} key={i}>
          {description}
        </span>
      ))}
    </Carousel>
  )
}

const mod = (n: number, m: number) => (m + (n % m)) % m

function useModularNumber(n: number, m: number): [number, (n: number) => void] {
  const [number, setNumber] = useState(n)
  return [number, (n: number) => setNumber(mod(n, m))]
}

function Bestiary(props: { beasts: Beast[] }) {
  const initialIndex = Math.floor(Math.random() * props.beasts.length)
  const [topIndex, setTopIndex] = useModularNumber(
    initialIndex,
    props.beasts.length
  )
  const [middleIndex, setMiddleIndex] = useModularNumber(
    initialIndex,
    props.beasts.length
  )
  const [bottomIndex, setBottomIndex] = useModularNumber(
    initialIndex,
    props.beasts.length
  )
  const setAll = (getNumber: () => number) => {
    setTopIndex(getNumber())
    setMiddleIndex(getNumber())
    setBottomIndex(getNumber())
  }
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    loadImages(Object.values(images)).then(() => setLoaded(true))
  }, [props.beasts])
  if (!loaded) return <div className="loader">Cargando...</div>
  return (
    <div id="bestiary">
      <div className="buttons">
        <button
          title="Mostrar la bestia anterior"
          onClick={() => setAll(() => topIndex + 1)}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </button>
        <button
          title="Crear bestia al azar"
          onClick={() =>
            setAll(() => Math.floor(Math.random() * props.beasts.length))
          }
        >
          <FontAwesomeIcon icon={faRandom} />
        </button>
        <button
          title="Mostrar la siguiente bestia"
          onClick={() => setAll(() => topIndex - 1)}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>
      </div>
      <div className="sections">
        <Section
          sections={props.beasts.map(beast => beast.top)}
          index={topIndex}
          onChange={setTopIndex}
        />
        <Section
          sections={props.beasts.map(beast => beast.middle)}
          index={middleIndex}
          onChange={setMiddleIndex}
        />
        <Section
          sections={props.beasts.map(beast => beast.bottom)}
          index={bottomIndex}
          onChange={setBottomIndex}
        />
      </div>
      <div className="descriptions">
        <Description
          descriptions={props.beasts.map(beast => beast.top.description)}
          index={topIndex}
        />
        <Description
          descriptions={props.beasts.map(beast => beast.middle.description)}
          index={middleIndex}
        />
        <Description
          descriptions={props.beasts.map(beast => beast.bottom.description)}
          index={bottomIndex}
        />
      </div>
    </div>
  )
}

render(
  <>
    <header>
      <img className="logo" src={logo} />
    </header>
    <main>
      <Bestiary
        beasts={[
          {
            top: {
              image: images['01-guepardo'],
              name: 'Gue',
              description: (
                <>
                  Fanático de la{' '}
                  <a href="https://noemivillegascalonge.com/project-type/animacion/">
                    animación
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-guepardo'],
              name: 'par',
              description: 'de majestuosa precisión',
            },
            bottom: {
              image: images['03-guepardo'],
              name: 'do',
              description: 'de las llanuras del After Effects',
            },
          },
          {
            top: {
              image: images['01-avestruz'],
              name: 'A',
              description: (
                <>
                  Ágil{' '}
                  <a href="https://noemivillegascalonge.com/project-type/ilustracion/">
                    ilustradora
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-avestruz'],
              name: 'ves',
              description: 'con delicados trazos',
            },
            bottom: {
              image: images['03-avestruz'],
              name: 'truz',
              description: 'por los caminos del Illustrator',
            },
          },
          {
            top: {
              image: images['01-zorro'],
              name: 'Zo',
              description: (
                <>
                  Astuto{' '}
                  <a href="https://noemivillegascalonge.com/project-type/diseno-grafico/">
                    diseñador gráfico
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-zorro'],
              name: 'rro',
              description: 'de incansable creatividad',
            },
            bottom: {
              image: images['03-zorro'],
              name: '',
              description: 'de los bosques del Indesign',
            },
          },
          {
            top: {
              image: images['01-castor'],
              name: 'Cas',
              description: (
                <>
                  Formidable{' '}
                  <a href="https://noemivillegascalonge.com/project-type/arquitectura-efimera-3/">
                    diseñador en Arquitectura Efímera
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-castor'],
              name: 'tor',
              description: 'de naturaleza ingeniosa',
            },
            bottom: {
              image: images['03-castor'],
              name: '',
              description: 'de los lagos del Autocad',
            },
          },
          {
            top: {
              image: images['01-erizo'],
              name: 'E',
              description: (
                <>
                  Carismático{' '}
                  <a href="https://noemivillegascalonge.com/project-type/arquitectura-efimera-3/">
                    infografísta en 3D
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-erizo'],
              name: 'ri',
              description: 'de curiosa perspectiva',
            },
            bottom: {
              image: images['03-erizo'],
              name: 'zo',
              description: 'de los mundos de 3D Studio y Sketchup',
            },
          },
          {
            top: {
              image: images['01-lobo'],
              name: 'Lo',
              description: (
                <>
                  Sociable{' '}
                  <a href="https://noemivillegascalonge.com/project-type/web/">
                    diseñador UX/UI
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-lobo'],
              name: 'bo',
              description: 'de ágiles movimientos',
            },
            bottom: {
              image: images['03-lobo'],
              name: '',
              description: 'de las montañas del Wordpress',
            },
          },
          {
            top: {
              image: images['01-oso'],
              name: 'Oso',
              description: (
                <>
                  Habilidoso{' '}
                  <a href="https://noemivillegascalonge.com/project-type/fotografia/">
                    fotógrafo
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-oso'],
              name: 'pan',
              description: 'siempre enfocado',
            },
            bottom: {
              image: images['03-oso'],
              name: 'da',
              description: 'de los árboles del Photoshop',
            },
          },
          {
            top: {
              image: images['01-jirafa'],
              name: 'Ji',
              description: (
                <>
                  Productiva{' '}
                  <a href="https://noemivillegascalonge.com/project-type/edicion-de-video/">
                    editora de video
                  </a>
                </>
              ),
            },
            middle: {
              image: images['02-jirafa'],
              name: 'ra',
              description: 'de naturaleza observadora',
            },
            bottom: {
              image: images['03-jirafa'],
              name: 'fa',
              description: 'de los pastos del Premiere',
            },
          },
        ]}
      />
    </main>
    <footer>
      © {new Date().getFullYear()} 👧🏽{' '}
      <a href="https://noemivillegascalonge.com/" target="__blank">
        Noemí Villegas
      </a>
    </footer>
  </>,
  document.getElementById('root')
)
